<template>
  <b-card no-body class="card-company-table">
    <b-card-header>
      <div>
        <b-card-title class="mb-1">Active Profiles</b-card-title>
      </div>
      <div class="d-flex align-items-center">
        <span v-if="spinnerPlayStop == true" style="margin-right: 25px">
          <b-spinner label="Loading..." />
        </span>
        <date-range-picker
          ref="picker"
          :opens="'left'"
          :locale-data="{
            direction: 'ltr',
            format: 'yyyy-mm-dd',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ],
            firstDay: 0,
          }"
          :minDate="'2019-01-01 00:00:00'"
          :maxDate="'2099-01-01 00:00:00'"
          :timePicker="false"
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="true"
          :autoApply="true"
          v-model="dateRange"
          @update="GET_MOST_POPUPLAR_User"
          :linkedCalendars="true"
        >
        </date-range-picker>
      </div>
    </b-card-header>

    <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
      <b-thead head-variant="light">
        <b-tr>
            <b-th>Name</b-th>
            <b-th>Install Referrer</b-th>
            <b-th>Signup with</b-th>
            <b-th>Time</b-th>
            <b-th>Gems</b-th>
            <!-- <b-th>Notifications</b-th> -->
            <b-th>Registration Date</b-th>
        </b-tr>
      </b-thead>
      <tbody v-if="get_users.total > 0">
        <tr v-for="item in get_users.data" :key="item._id">
          <td>
            <div class="d-flex align-items-center">
              <feather-icon icon="EyeIcon" v-b-modal.modal-app-active-user-logs @click="getProfileLogs(item)" size="17"
                class="qu-icon-grey mr-1 curser-pointer" />
              <b-link :to="{
                path: '/user-report/' + item.userKey,
                }">
                <div>
                  <div class="font-weight-bolder">
                    {{ item.name }}
                  </div>
                </div>
              </b-link>
              <div v-if="item.gender">({{ item.gender }})</div>
            </div>
          </td>

          <td>
            <div class="font-weight-bolder">
              <span class="text-capitalize" v-if="item.installReferrer == 'not_set'">
                <b-link @click="showInstallReferrerSource(item.installReferrerSource)">
                  Not Set
                </b-link>
              </span>
              <span class="text-capitalize" v-else>
                <span v-if="item.installReferrer">
                  <b-link @click="showInstallReferrerSource(item.installReferrerSource)">
                    {{ item.installReferrer }}
                  </b-link>
                </span>
                <span v-else>N/A</span>
              </span>
            </div>
          </td>

          <td>
            <div class="d-flex align-items-center">
              <div>
                <span v-if="item.userBy" class="mr-1">
                  <img v-if="item.userBy == 'sign-up-with-gmail'" style="width: 15px" src="/googleIcon.png"
                    alt="google-icon" />
                  <img v-if="item.userBy == 'sign-up-with-apple'" style="width: 15px" src="/appleIcon.png"
                    alt="google-icon" />
                  <feather-icon v-if="item.userBy == 'create-account'" size="15" style="color: #6fbfff"
                    icon="KeyIcon" /> </span>

                <span v-if="item.devicePlatform" class="mr-1">
                  <img v-if="item.devicePlatform == 'ios'" style="width: 15px" src="/ios.png" alt="ios-icon" />
                  <img v-if="item.devicePlatform == 'android'" style="width: 15px" src="/devicon_android.png"
                    alt="android-icon" /> </span>

                <span v-if="item.source_country">
                  <flag-component :countryCode="item.source_country" size="15"/>
                </span>
              </div>
            </div>
          </td>

          <td>
            <div class="d-flex align-items-center">
              <div>
                <div class="font-weight-bolder">
                  {{ item.time }}
                </div>
              </div>
            </div>
          </td>

          <td>
            <div class="d-flex align-items-center">
              <div>
                <div class="font-weight-bolder">
                  <appUsersGemsHistory
                    :profile="item"
                    name="active-profiles"
                  ></appUsersGemsHistory>
                </div>
              </div>
            </div>
          </td>

          <!-- <td>
            <div class="d-flex align-items-center">
              <div>
                <div class="font-weight-bolder">
                  <appUsersPushNotificationsHistory
                    :profile="item"
                    name="active-profiles"
                  ></appUsersPushNotificationsHistory>
                </div>
              </div>
            </div>
          </td> -->

          <td>
            <div class="font-weight-bolder">
              {{ Helpers.formatDateTime(item.createdAt, "DD MMM YYYY") }}
              <!-- <div>{{ item.timeZone }}</div> -->
            </div>
          </td>
          
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="10" class="text-center">
            <div class="p-2">No Record Found</div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
    <div class="mx-2 mb-1">
      <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
        <div class="dataTables_info mt-1">Showing {{get_users.from}} to {{get_users.to}} of {{get_users.total}} entries</div>
        <pagination 
          class="mt-1 mb-0"
          :data="get_users"
          :limit=5
          @pagination-change-page="getUsers"
        ></pagination>
      </div>
    </div>
    
    <!-- <div style="display: flex; flex-direction: row-reverse">
      <b-pagination class="mt-1 mr-1" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="custom-table"
        size="sm"></b-pagination>
    </div> -->
    <b-modal id="modal-app-active-user-logs" size="xl" centered :title="modalTitle" hide-footer @hidden="resetModal">
      <div style="overflow: hidden" v-if="appLogs.data && appLogs.data.length > 0">
        <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
              <b-th style="width: 100px;">Grade</b-th>
              <b-th style="width: 200px;">Type</b-th>
              <b-th style="width: 100px;">subject</b-th>
              <b-th style="width: 300px;">topic</b-th>
              <b-th style="width: 150px;">screen</b-th>
              <b-th style="width: 300px;">Click</b-th>
              <b-th style="width: 200px;">created</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(log, index) in appLogs.data">
              <b-tr :key="index">
                <b-td>
                  <div v-if="log.grade">
                    {{ log.grade }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.logType">
                    {{ log.logType }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.subject">
                    {{ log.subject }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.topic">
                    {{ log.topic }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.screen">
                    {{ log.screen }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.clickedOnSubject">{{ log.clickedOnSubject }}</div>
                  <div v-else-if="log.clickedOnTopic">{{ log.clickedOnTopic }}</div>
                  <div v-else-if="log.clickedOnLevel">{{ log.clickedOnLevel }}</div>
                  <div v-else-if="log.clickedOn">{{ log.clickedOn }}</div>
                  <div v-else="log.clickedOn">{{ log.clickedOn }}</div>
                </b-td>
                <b-td>
                  <div v-if="log.created">
                    {{ log.created }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
        <div class="d-flex justify-content-end">
          <pagination class="mt-1 mb-0 mr-1" :data="appLogs" :limit=2 @pagination-change-page="profileLog">
          </pagination>
        </div>
      </div>
      <div v-else class="text-center mb-2">No records found</div>
    </b-modal>
    <b-modal centered size="lg" v-model="showInstallReferrerSourceModal" title="Install Referrer Detail" hide-footer>
        <div>
          <div v-if="InstallReferrerSourceData && InstallReferrerSourceData">
            <table class="table table-bordered">
                <tbody>
                    <tr v-for="(item, key) in Helpers.jsonToArray(InstallReferrerSourceData)" :key="key">
                        <th>{{ item.key }}</th>
                        <td>
                          <div style="word-break: break-all;">
                            {{ item.value }}
                          </div>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
          <div v-else>
            No Record Found
          </div>
        </div>
    </b-modal>

  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BTable,
  BAvatar,
  BImg,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BFormInput,
  BMediaAside,
  BMediaBody,
  BDropdown,
  BPagination,
  BDropdownItem,
  BSpinner,
  BLink,
  BModal,
  BTableSimple,
  BTh,
  BTbody,
  BTr,
  BThead,
  BTd
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import FlagComponent from "../components/FlagComponent.vue";
import axios from "axios";
import appUsersGemsHistory from "../Models/appUsersGemsHistory.vue";
import appUsersPushNotificationsHistory from "../Models/appUsersPushNotificationsHistory.vue";
import TokenService from "../../TokenService";
import * as Helpers from "../../Helpers";

export default {
  components: {
    BTd,
    BTableSimple,
    BTh,
    BTbody,
    BTr,
    BThead,
    BModal,
    FlagComponent,
    BButton,
    BCard,
    BTable,
    BSpinner,
    BLink,
    BCardHeader,
    DateRangePicker,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormInput,
    BMedia,
    BPagination,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BAvatar,
    appUsersGemsHistory,
    appUsersPushNotificationsHistory,
  },
  directives: {
    Ripple,
  },
  props: {
    sendDatesPropsToAllComponent: {
      type: Object,
      required: true,
    },
  },
  watch: {
    sendDatesPropsToAllComponent(val) {
      if (this.sendDatesPropsToAllComponent.date) {
        this.selected = this.sendDatesPropsToAllComponent.gradeId;
        if (this.selected.className == "All") {
          this.gradeName = "All -> All Grades";
        }
        let first =
          this.sendDatesPropsToAllComponent.date.startDate.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          );

        let last =
          this.sendDatesPropsToAllComponent.date.endDate.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          );
        this.date = first + " - " + last;
        this.date = this.date.replaceAll("/", "-");
        this.dateRange = this.sendDatesPropsToAllComponent.date;
        this.GET_MOST_POPUPLAR_User(
          this.sendDatesPropsToAllComponent.date
        );
      } else {
        this.GET_MOST_POPUPLAR_User(this.sendDatesPropsToAllComponent);
      }
    },
  },
  data() {
    return {
      spinnerPlayStop: true,
      perPage: 10,
      currentPage: 1,
      dateRange: {},
      gradeName: "All -> All Grades",
      date: "",
      rangePicker: ["2022-05-01", "2022-05-10"],
      Picker: "2022-05-01 to 2022-05-10",
      selected: "",
      fields: [
        { key: "name", label: "Name" },
        { key: "installReferrer", label: "Install Referrer" },
        { key: "source_country", label: "Signup with" },
        { key: "time", label: "TIME" },
        { key: "gem", label: "GEMS" },
        { key: "pushNotifications", label: "Notifications" },
        { key: "createdAt", label: "Registration Date" },
      ],
      dashboardTable: [],
      bearerToken: null,
      appLogs: [],
      selectedUser: {
        name: ''
      },
      TokenService,
      Helpers,
      showInstallReferrerSourceModal: false,
      InstallReferrerSourceData: {},
      
      get_users: {},
    };
  },
  created() {
    console.log('this.Helpers.getTodayDates()=',this.Helpers.getTodayDates());
    this.dateRange.startDate = this.Helpers.getTodayDates().startDate;
    this.dateRange.endDate = this.Helpers.getTodayDates().endDate;
    this.GET_MOST_POPUPLAR_User(this.Helpers.getTodayDates());
  },
  computed: {
    rows() {
      return this.dashboardTable.length;
    },
    modalTitle() {
      return `${this.selectedUser.name} Activity`;
    },
  },

  mounted() {
    this.bearerToken = "Bearer " + localStorage.getItem("_t");
  },

  methods: {
    showInstallReferrerSource(data) {
      this.showInstallReferrerSourceModal = true;
      this.InstallReferrerSourceData = data;
    },
    resetModal() {
      this.appLogs =  [];
    },
    async getProfileLogs(user) {
      this.selectedUser = user;
      this.profileLog();
    },
    async profileLog(page = 1) {
      const payload = { id: this.selectedUser.userKey };
      try {
        const resp = await axios.post(process.env.VUE_APP_API_URL + `/zape-app/logs?page=${page}`, payload, 
        {
          headers: {
            Authorization: this.bearerToken,
          },
        });
        this.appLogs = resp.data.logs;
      } catch (error) {
        console.log('error', error);
      }
    },
    GET_MOST_POPUPLAR_User(value) {
      this.getUsers();
    },

    getUsers: function (page = 1) {
      this.spinnerPlayStop = true;
      var startDate = this.Helpers.changeDateFormat(this.dateRange.startDate);
      var endDate = this.Helpers.changeDateFormat(this.dateRange.endDate);
      const payload = {
        startDate: startDate,
        endDate: endDate,
      }
      axios
        .post(process.env.VUE_APP_API_URL + `/most-popular/users?page=${page}`, payload,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
        })
        .then((res) => {
          this.get_users = res.data;
          this.spinnerPlayStop = false;
        })
        .catch(function (error) {
          this.spinnerPlayStop = false;
          console.log("error", error);
        });
    },

    /*
    getUsers(data) {
      var startDate = this.Helpers.changeDateFormat(this.dateRange.startDate);
      var endDate = this.Helpers.changeDateFormat(this.dateRange.endDate);
      this.spinnerPlayStop = true;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/most-popular/users",
          {
            startDate: startDate,
            endDate: endDate,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          let resData = response.data;
          this.dashboardTable = resData;
          this.spinnerPlayStop = false;
        });
    },
    */

  },
};
</script>
