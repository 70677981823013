<template>
  <b-card no-body class="card-company-table">
    <b-card-header>
      <b-card-title>Grades Progress</b-card-title>

      <b-card-text class="font-small-2 mr-25 mb-0">
        <!-- Updated 1 month ago -->
      </b-card-text>
      <span class="d-flex justify-content-end">
        <span v-if="spinnerPlayStop == true" style="margin-right: 25px">
          <b-spinner label="Loading..." />
        </span>
      </span>
      <div class="d-flex" >
      <span style="width: 100%">
        <v-select
          v-model="selected"
          :close-on-select="true"
          :options="optionsData"
          label="className"
          input-id="add-text"
          @input="getAppUserProgress()"
        >
        </v-select>
      </span>
    </div>
    </b-card-header>
    <!-- {{rows}} -->
    
    <b-table
      :items="dashboardTable"
      responsive
      :fields="fields"
      class="mb-0"
      id="custom-table"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <!-- {{ data.item.grade }} -->
          <div>
            <b-link
            :to="{
              path: '/user-report/' + data.item.key,
            }"
          >
            <div class="font-weight-bolder">
              {{ data.item.name }}
            </div>
            </b-link>
            ( {{ data.item.grade }})
          </div>
        </div>
      </template>
      <template #cell(grade)="data">
        <div class="d-flex align-items-center">
          <!-- {{ data.item.grade }} -->
          <div>
            <div class="font-weight-bolder">
              {{ data.item.grade }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(progress)="data">
        <div class="d-flex align-items-center">
          <!-- {{ data.item.grade }} -->
          <div>
            <div class="font-weight-bolder">
              {{ parseFloat(data.item.progress.toFixed(2)) + "%" }}
            </div>
          </div>
        </div>
      </template>
    </b-table>
    <div style="display: flex; flex-direction: row-reverse">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="custom-table"
        size="sm"
      ></b-pagination>
    </div>
  </b-card>
  <!-- <div>
      
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="exportdata"
      >
        Export data
      </b-button>
      
    </div> -->
</template>
  
  <script>
import {
  BButton,
  BCard,
  BTable,
  BAvatar,
  BImg,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BDropdown,
  BLink,
  BDropdownItem,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { methods } from "vue-echarts";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    BButton,
    BCard,
    BTable,
    BPagination,
    vSelect,
    BCardHeader,
    BCardTitle,
    DateRangePicker,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BAvatar,
    BLink
  },
  directives: {
    Ripple,
  },
  props: {
    sendDatesPropsToAllComponent: {
      type: Object,

      required: true,
    },
  },
  watch: {
    sendDatesPropsToAllComponent(val) {
      // this.GET_MOST_POPUPLAR_TOPIC(this.sendDatesPropsToAllComponent.date);
      if (this.sendDatesPropsToAllComponent.date) {
        // console.log("dateeee");
        this.selected = this.sendDatesPropsToAllComponent.gradeId;
        this.getAppUserProgress();
      }
      //  console.log("sendDatesPropsToAllComponent",this.sendDatesPropsToAllComponent);
    },
  },
  data() {
    return {
      spinnerPlayStop: false,
      perPage: 10,
      currentPage: 1,
      selected: {},
      optionsData: [""],
      dateRange: {},
      rangePicker: ["2022-05-01", "2022-05-10"],
      Picker: "2022-05-01 to 2022-05-10",
      fields: [
        { key: "name", label: "NAME" },
        // { key: "grade", label: "GRADE" },
        { key: "progress", label: "PROGRESS" },
      ],

      dashboardTable: [],
    };
  },
  computed: {
    rows() {
      // return 39;
      return this.dashboardTable.length;
    },
  },

  created() {
    this.getAllGrade();
  },

  methods: {
    getAllGrade() {
      // return 'dpm';
      this.spinnerPlayStop = true;
      axios
        .get(process.env.VUE_APP_API_URL + "/grade/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // this.optionsData = response.data;
          // if (this.optionsData[0]) {
          //   this.selected = this.optionsData[0];
          // }
          this.optionsData.push(this.selected);
          this.optionsData.push(...response.data);
          // this.getAppUserProgress();
        });
    },
    getAppUserProgress() {
      this.spinnerPlayStop = true;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/zape-app/grade-progress/users",
          { gradeId: this.selected._id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.dashboardTable = response.data;
          this.spinnerPlayStop = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
  