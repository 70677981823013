<template>
  <b-card no-body class="card-company-table">
    <b-card-header>
      <b-card-title>Most Popular Topics</b-card-title>

      <b-card-text class="font-small-2 mr-25 mb-0"> </b-card-text>
      <span v-if="spinnerPlayStop == true" style="margin-right: 25px">
        <b-spinner label="Loading..." />
      </span>
      <span class="d-flex">
        <span class="font-weight-bolder" style="font-size: 12px;">{{ this.date + "  " }}</span>
        <span style="margin-left: 10px;font-size: 12px" class="font-weight-bolder">{{
          "    " + gradeName
        }}</span>
      </span>
    </b-card-header>

    <b-table
      :items="dashboardTable"
      responsive
      :fields="fields"
      class="mb-0"
      id="custom-table"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(topic)="data">
        <div class="d-flex align-items-center">
          <div>
            {{ data.item.class }} / {{ data.item.subject }} /
            <div class="font-weight-bolder">
              {{ data.item.topic }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(time)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.Time }}
            </div>
          </div>
        </div>
      </template>
    </b-table>
    <div style="display: flex; flex-direction: row-reverse">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="custom-table"
        size="sm"
      ></b-pagination>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BTable,
  BAvatar,
  BImg,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { methods } from "vue-echarts";
import axios from "axios";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    BButton,
    BCard,
    BTable,
    BPagination,
    BCardHeader,
    BCardTitle,
    DateRangePicker,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BAvatar,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    sendDatesPropsToAllComponent: {
      type: Object,
      required: true,
    },
  },
  watch: {
    sendDatesPropsToAllComponent(val) {
      if (this.sendDatesPropsToAllComponent.date) {
        this.selected = this.sendDatesPropsToAllComponent.gradeId;
        if (this.selected.className == "All") {
          this.gradeName = "All -> All Grades";
        }
        let first =
          this.sendDatesPropsToAllComponent.date.startDate.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          );

        let last =
          this.sendDatesPropsToAllComponent.date.endDate.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          );
        this.date = first + " - " + last;
        this.date = this.date.replaceAll("/", "-");
        this.dateRange = this.sendDatesPropsToAllComponent.date;
        this.GET_MOST_POPUPLAR_TOPIC(this.sendDatesPropsToAllComponent.date);
      } else {
        this.GET_MOST_POPUPLAR_TOPIC(this.sendDatesPropsToAllComponent);
      }
    },
  },
  data() {
    return {
      spinnerPlayStop: true,
      perPage: 10,
      currentPage: 1,
      selected: "",
      date: "",
      gradeName: "All -> All Grades",
      dateRange: {},
      rangePicker: ["2022-05-01", "2022-05-10"],
      Picker: "2022-05-01 to 2022-05-10",
      fields: [
        { key: "topic", label: "TOPIC" },
        { key: "time", label: "TIME" },
      ],

      dashboardTable: [],
    };
  },
  computed: {
    rows() {
      return this.dashboardTable.length;
    },
  },

  methods: {
    GET_MOST_POPUPLAR_TOPIC(value) {
      this.spinnerPlayStop = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      if (startDate == lastDate) {
        var datetime = {
          date: `${startDate}`,
          gradeId: this.selected._id,
        };
      } else {
        var datetime = {
          date: `${startDate + " " + "to" + " " + lastDate}`,
          gradeId: this.selected._id,
        };
      }
      axios
        .post(process.env.VUE_APP_API_URL + "/most-popular/topic", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          this.dashboardTable = response.data;
          this.spinnerPlayStop = false;
        });
    },
    exportdata: function () {
      axios({
        url: process.env.VUE_APP_API_URL + "/exportalldata",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "data.json");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
};
</script>

<style>
</style>
